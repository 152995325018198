// eslint-disable
import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const BannerPage = () => {
  const [showUpload, setShowUpload] = useState(false);
  const [banners, setBanners] = useState([]);

  const { slug } = useParams();
  const location = useLocation();

  const handleSidebarToggle = () => {
    const sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle("active");
  };

  // =====================================================
  const fetchBanners = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/banners`
      );
      const bannersData = response.data?.data || [];
      setBanners(bannersData);
      console.log("API response (Banners): ", bannersData);
    } catch (error) {
      toast.error("Failed to fetch banners");
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  // ======================================================
  const [image, setImage] = useState(null);

  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("userSlug", slug);
    if (image) formData.append("image", image);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/banners`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        toast.success("Banner created successfully");
        fetchBanners();
        formRef.current.reset();
        setImage(null);
        setShowUpload(false);
      } else {
        toast.error("Failed to create banner");
      }
    } catch (error) {
      toast.error("An error occurred while creating the banner");
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // Define the nav items with multiple links and nested collapsible links
  const navItems = [
    {
      id: 1,
      title: "Pigeon Owners",
      icon: "bi bi-person",
      links: [], // No sub-links, so this will render as a single link
      to: `/pigeonowner/${slug}`,
    },

    {
      id: 2,
      title: "Tournament",
      icon: "bi bi-trophy",
      links: [
        { to: `/Tournament/${slug}`, text: "Create Tournament" },
        { to: `/edit/${slug}`, text: "All Tournament" },
      ],
    },
    {
      id: 3,
      title: "Banner",
      icon: "bi bi-trophy",
      links: [], // No sub-links, so this will render as a single link
      to: `/banner/${slug}`,
    },
    {
      id: 4,
      title: "Logout",
      icon: "bi bi-box-arrow-right",
      links: [],
      to: "/",
    },
  ];
  // Add more nav items as needed

  // Initialize state to keep track of collapsed items
  const [collapsedItems, setCollapsedItems] = useState(
    navItems.map(() => false)
  );
  const [collapsedSubItems, setCollapsedSubItems] = useState(
    navItems.map((item) => item.links.map(() => false))
  );

  // Toggle function for collapse state of main items
  const handleCollapse = (index) => {
    setCollapsedItems(
      collapsedItems.map((item, i) => (i === index ? !item : item))
    );
  };

  // Toggle function for collapse state of sub-items
  const handleSubCollapse = (itemIndex, subIndex) => {
    setCollapsedSubItems(
      collapsedSubItems.map((subItems, i) =>
        i === itemIndex
          ? subItems.map((subItem, j) => (j === subIndex ? !subItem : subItem))
          : subItems
      )
    );
  };

  // ================================================================

  const [club, setClub] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClubDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/auth/clubs/${slug}`
        );
        setClub(response.data.club);
        // console.log("Club details fetched: ", response.data.club);
      } catch (error) {
        setError("Error fetching details");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchClubDetails();
  }, [slug]);
  const toggleModal = () => {
    setShowUpload(!showUpload);
  };

  const handleDelete = async (bannerId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/banner/${bannerId}`
      );
      toast.success("Banner deleted successfully");
      fetchBanners();
    } catch (error) {
      toast.error("Failed to delete banner");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div>
        <header
          id="header"
          className="header fixed-top d-flex align-items-center"
        >
          <div className="d-flex align-items-center justify-content-between">
            <Link className="logo d-flex align-items-center">
              <span className="zz d-none d-lg-block">Sona Punjab</span>
            </Link>
            <button
              id="toggle-sidebar-btn"
              className="bi bi-list toggle-sidebar-btn"
              onClick={handleSidebarToggle}
            />
          </div>
          {/* End Logo */}
          <div className="search-bar">
            <form className="search-form d-flex align-items-center">
              <input
                type="text"
                placeholder="Search"
                title="Enter search keyword"
              />
              <button type="submit" title="Search">
                <i className="bi bi-search" />
              </button>
            </form>
          </div>
          {/* End Search Bar */}
          <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-center">
              <li className="nav-item dropdown pe-3">
                <Link className="nav-link nav-profile d-flex align-items-center pe-0">
                  <span className="  ps-2">Welcome {club.name} </span>
                </Link>
                {/* End Profile Iamge Icon */}
              </li>
              {/* End Profile Nav */}
            </ul>
          </nav>
          {/* End Icons Navigation */}
        </header>
        {/* End Header */}
        {/* ======= Sidebar ======= */}
        <aside id="sidebar" className="sidebar" data-background-color="dark">
          <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-item">
              <a className="nav-link " href={`clubdetail/${slug}`}>
                <i className="bi bi-grid" />
                <span>Dashboard</span>
              </a>
            </li>
            {/* End Dashboard Nav */}

            {/* ======================================================================================= */}

            {navItems.map((item, index) => (
              <li className="nav-item" key={item.id}>
                {item.links.length > 0 ? (
                  <>
                    <Link
                      className={`nav-link collapsed ${
                        location.pathname.startsWith(item.to)
                          ? "active bg-orange"
                          : ""
                      }`}
                      href="#a"
                      onClick={() => handleCollapse(index)}
                    >
                      <i className={item.icon} />
                      <span>{item.title}</span>
                      <i
                        className={`bi ${
                          collapsedItems[index]
                            ? "bi-chevron-up"
                            : "bi-chevron-down"
                        } ms-auto`}
                      />
                    </Link>
                    <ul
                      id={`components-nav-${item.id}`}
                      className={`nav-content collapse ${
                        collapsedItems[index] ? "show" : ""
                      }`}
                      data-bs-parent="#sidebar-nav"
                    >
                      {item.links.map((link, linkIndex) => (
                        <React.Fragment key={linkIndex}>
                          {link.subLinks ? (
                            <li>
                              <a
                                href={link.to}
                                className={`nav-link collapsed ${
                                  location.pathname === link.to
                                    ? "active bg-orange"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleSubCollapse(index, linkIndex)
                                }
                              >
                                <i className="bi bi-circle" />
                                <span>{link.text}</span>
                                <i
                                  className={`bi ${
                                    collapsedSubItems[index][linkIndex]
                                      ? "bi-chevron-up"
                                      : "bi-chevron-down"
                                  } ms-auto`}
                                />
                              </a>
                              <ul
                                className={`nav-content collapse ${
                                  collapsedSubItems[index][linkIndex]
                                    ? "show"
                                    : ""
                                }`}
                              >
                                {link.subLinks.map((subLink, subLinkIndex) => (
                                  <li key={subLinkIndex}>
                                    <Link to={subLink.to}>
                                      <i className="bi bi-circle" />
                                      <span>{subLink.text}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ) : (
                            <li>
                              <Link
                                to={link.to}
                                className={`nav-link ${
                                  location.pathname === link.to
                                    ? "active bg-orange"
                                    : ""
                                }`}
                              >
                                <i className="bi bi-circle" />
                                <span>{link.text}</span>
                              </Link>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                    </ul>
                  </>
                ) : item.title === "Logout" ? (
                  <button className="aab nav-link">
                    <i className={item.icon} />
                    <span id="qa">{item.title}</span>
                  </button>
                ) : (
                  <Link
                    to={item.to}
                    className={`aab nav-link ${
                      location.pathname === item.to ? "active bg-orange" : ""
                    }`}
                  >
                    <i className={item.icon} />
                    <span id="qa">{item.title}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </aside>
      </div>

      {/* End Sidebar*/}
      {/* ============================================================================ */}

      <div className="banner-management container-fluid">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-9" style={{ marginTop: "90px" }}>
            <div className="upload-banner">
              <h2>All Banners</h2>
              <button className="btn btn-primary mb-3" onClick={toggleModal}>
                Add Banner
              </button>
            </div>

            {showUpload && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <span className="close-button" onClick={toggleModal}>
                    &times;
                  </span>
                  <h3>Upload Banner</h3>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    {image && (
                      <img
                        src={URL.createObjectURL(image)}
                        alt="Preview"
                        style={{ width: "100px", marginLeft: "10px" }}
                      />
                    )}
                    <button
                      className="btn mt-3"
                      onClick={handleSubmit}
                      style={{ backgroundColor: "#E04622", color: "White" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}

            <table className="table table-bordered mt-4">
              <thead className="thead-dark">
                <tr>
                  <th>Banner Image</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {banners.length > 0 ? (
                  banners.map((banner) => (
                    <tr key={banner._id}>
                      <td>
                        <img
                          src={`/uploads/${banner.image}`}
                          alt="Banner"
                          className="img-fluid"
                          style={{ width: "150px" }}
                        />
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleDelete(banner._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No banners available</td>
                  </tr>
                )}

                {/* {banners.map((banner) => (
                  <tr key={banner._id}>
                    <td>
                      <img
                        src={banner.image}
                        alt="Banner"
                        className="img-fluid"
                        style={{ width: "150px" }}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(banner._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerPage;
