import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const Tournament = () => {
  const { slug } = useParams();
  const [rows, setRows] = useState([]);

  const location = useLocation();

  const handleSidebarToggle = () => {
    const sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle("active");
  };

  // =========================================================================

  const navItems = [
    {
      id: 1,
      title: "Pigeon Owners",
      icon: "bi bi-person",
      links: [], // No sub-links, so this will render as a single link
      to: `/pigeonowner/${slug}`,
    },

    {
      id: 2,
      title: "Tournament",
      icon: "bi bi-trophy",
      links: [
        { to: `/Tournament/${slug}`, text: "Create Tournament" },
        { to: `/edit/${slug}`, text: "All Tournament" },
      ],
    },
    {
      id: 3,
      title: "Banner",
      icon: "bi bi-trophy",
      links: [], // No sub-links, so this will render as a single link
      to: `/banner/${slug}`,
    },
    // {
    //   id: 4,
    //   title: "All Partcipent",
    //   icon: "bi bi-trophy",
    //   links: [], // No sub-links, so this will render as a single link
    //   to: `/admin-user/${slug}`,
    // },
    {
      id: 4,
      title: "Logout",
      icon: "bi bi-box-arrow-right",
      links: [],
      to: "/"
    },
  ];

  const [collapsedItems, setCollapsedItems] = useState(
    navItems.map(() => false)
  );
  const [collapsedSubItems, setCollapsedSubItems] = useState(
    navItems.map((item) => item.links.map(() => false))
  );
  const isActiveNavItem = (item) => {
    return (
      location.pathname === item.to ||
      item.links.some((link) => location.pathname.startsWith(link.to))
    );
  };

  // Toggle function for collapse state of main items
  const handleCollapse = (index) => {
    setCollapsedItems(
      collapsedItems.map((item, i) => (i === index ? !item : item))
    );
  };

  // Toggle function for collapse state of sub-items
  const handleSubCollapse = (itemIndex, subIndex) => {
    setCollapsedSubItems(
      collapsedSubItems.map((subItems, i) =>
        i === itemIndex
          ? subItems.map((subItem, j) => (j === subIndex ? !subItem : subItem))
          : subItems
      )
    );
  };

  // ========================================================creat owner========

  const [image, setImage] = useState(null);
  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("clubName", club.cname);
    formData.append("userSlug", slug);
    formData.append("name", event.target.tournament.value);
    formData.append(
      "dates",
      JSON.stringify(
        rows.map((_, index) => event.target[`date-${index}`].value)
      )
    );
    formData.append(
      "amountOfPrizes",
      JSON.stringify(
        rows.map((_, index) => event.target[`amount-${index}`].value)
      )
    );
    formData.append("startTime", event.target.time.value);
    formData.append("helperPigeon", event.target.helper.value);
    formData.append("status", event.target.status.value);
    formData.append("numberOfPigeons", event.target.pigeons.value);
    formData.append("noOfPrizes", event.target.prizes.value);
    formData.append("numberOfDays", event.target.days.value);
    formData.append("numberOfLofts", event.target.lofts.value);

    formData.append(
      "owners",
      JSON.stringify(
        selectedOwners.map((owner) => ({
          ownerId: owner._id,
          ownerName: owner.name,
        }))
      )
    );

    if (image) {
      formData.append("image", image);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/tournament`,
        {
          method: "POST",
          body: formData,
        }
      );
      console.log("API Response:", response);
      if (response.ok) {
        toast.success("Tournament created successfully");
        formRef.current.reset();
        setSelectedOwners([]);
        setRows([]);
        setImage(null);
        fetchOwners();
      } else {
        toast.error("Failed to create tournament");
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  // =================== get owner ================

  const [owners, setOwners] = useState([]);
  const [selectedOwners, setSelectedOwners] = useState([]);

  const fetchOwners = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/owner/${slug}`
      );
      console.log("All Owners response", response);
      setOwners(response.data);
    } catch (error) {
      toast.error("Failed to fetch owners");
    }
  };

  useEffect(() => {
    fetchOwners();
  }, [slug]);
  const handleSelectOwner = (owner) => {
    setSelectedOwners((prev) => [...prev, owner]);
  };

  const handleRemoveOwner = (owner) => {
    setSelectedOwners((prev) => prev.filter((o) => o._id !== owner._id));
  };

  //============get owners=========
  const [club, setClub] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setSelectedClub] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClubDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/auth/clubs/${slug}`
        );
        setClub(response.data.club);
      } catch (error) {
        setError("Error fetching details");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchClubDetails();
  }, [slug]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  //==================================================dates

  const handleChange = (e) => {
    const numRows = parseInt(e.target.value, 10);
    setRows(Array.from({ length: numRows }, (_, i) => i));
  };

  // ===============================================================================
  return (
    <>
      <div>
        {/* ======= Header ======= */}
        <header
          id="header"
          className="header fixed-top d-flex align-items-center"
        >
          <div className="d-flex align-items-center justify-content-between">
            <a href="/create" className="logo d-flex align-items-center">
              <span className="zz d-none d-lg-block">Sona Punjab</span>
            </a>
            <button
              id="toggle-sidebar-btn"
              className="bi bi-list toggle-sidebar-btn"
              onClick={handleSidebarToggle}
            />
          </div>
          {/* End Logo */}
          <div className="search-bar">
            <form className="search-form d-flex align-items-center">
              <input
                type="text"
                placeholder="Search"
                title="Enter search keyword"
              />
              <button type="submit" title="Search">
                <i className="bi bi-search" />
              </button>
            </form>
          </div>
          {/* End Search Bar */}
          <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-center">
              <li className="nav-item dropdown pe-3">
                <Link className="nav-link nav-profile d-flex align-items-center pe-0">
                  <span className="  ps-2">Welcome {club.name} </span>
                </Link>
              </li>
            </ul>
          </nav>
        </header>
        {/* ======= Sidebar ======= */}
        <aside id="sidebar" className="sidebar" data-background-color="dark">
          <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-item">
              <a className="nav-link " href={`clubdetail/${slug}`}>
                <i className="bi bi-grid" />
                <span>Dashboard</span>
              </a>
            </li>
            {/* End Dashboard Nav */}

            {navItems.map((item, index) => (
              <li className="nav-item" key={item.id}>
                {item.links.length > 0 ? (
                  <>
                    <Link
                      className={`nav-link collapsed ${
                        isActiveNavItem(item) ? "active bg-orange" : ""
                      }`}
                      href="#a"
                      onClick={() => handleCollapse(index)}
                    >
                      <i className={item.icon} />
                      <span>{item.title}</span>
                      <i
                        className={`bi ${
                          collapsedItems[index]
                            ? "bi-chevron-up"
                            : "bi-chevron-down"
                        } ms-auto`}
                      />
                    </Link>
                    <ul
                      id={`components-nav-${item.id}`}
                      className={`nav-content collapse ${
                        collapsedItems[index] ? "show" : ""
                      }`}
                      data-bs-parent="#sidebar-nav"
                    >
                      {item.links.map((link, linkIndex) => (
                        <React.Fragment key={linkIndex}>
                          {link.subLinks ? (
                            <li>
                              <a
                                href={link.to}
                                className={`nav-link collapsed ${
                                  location.pathname === link.to
                                    ? "active bg-orange"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleSubCollapse(index, linkIndex)
                                }
                              >
                                <i className="bi bi-circle" />
                                <span>{link.text}</span>
                                <i
                                  className={`bi ${
                                    collapsedSubItems[index][linkIndex]
                                      ? "bi-chevron-up"
                                      : "bi-chevron-down"
                                  } ms-auto`}
                                />
                              </a>
                              <ul
                                className={`nav-content collapse ${
                                  collapsedSubItems[index][linkIndex]
                                    ? "show"
                                    : ""
                                }`}
                              >
                                {link.subLinks.map((subLink, subLinkIndex) => (
                                  <li key={subLinkIndex}>
                                    <Link to={subLink.to}>
                                      <i className="bi bi-circle" />
                                      <span>{subLink.text}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ) : (
                            <li>
                              <Link
                                to={link.to}
                                className={`nav-link ${
                                  location.pathname === link.to
                                    ? "active bg-orange"
                                    : ""
                                }`}
                              >
                                <i className="bi bi-circle" />
                                <span>{link.text}</span>
                              </Link>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                    </ul>
                  </>
                ) : item.title === "Logout" ? (
                  <button className="aab nav-link">
                    <i className={item.icon} />
                    <span id="qa">{item.title}</span>
                  </button>
                ) : (
                  <Link
                    to={item.to}
                    className={`aab nav-link ${
                      location.pathname === item.to ? "active bg-orange" : ""
                    }`}
                  >
                    <i className={item.icon} />
                    <span id="qa">{item.title}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </aside>
      </div>
      {/* ======================================== End Sidebar ==================================== */}
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{club.cname}</h1>
          <nav>
            <ol className="breadcrumb mt-3">
              <li className="breadcrumb-item">
                <a href="/">{club.cname}</a>
              </li>
              <li className="breadcrumb-item active">Create Tournament</li>
            </ol>
          </nav>
        </div>
        <section className="section-dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="card-title">Enter Tournament Details</h5>
                  </div>

                  {/* ================================================= Form ============================== */}
                  <form
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                    ref={formRef}
                  >
                    <div className="row">
                      <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                        <input
                          value={club.cname}
                          onChange={(e) => setSelectedClub(e.target.value)}
                          className="form-control"
                          required
                          readOnly
                          style={{ display: "none" }}
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                        <input
                          type="text"
                          id="tournament"
                          name="tournament"
                          placeholder="Tournament Name"
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                        <label htmlFor="inputimage" id="pp">
                          Upload Image
                        </label>
                        <input
                          type="file"
                          id="image"
                          name="image"
                          className="form-control"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                        <select
                          id="status"
                          name="status"
                          className="form-control"
                          required
                        >
                          <option value="">Select Status</option>
                          <option value="Active">Active</option>
                          <option value="Paused">Not Active</option>
                        </select>
                      </div>

                      <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                        <input
                          type="number"
                          id="days"
                          name="days"
                          placeholder="Number of days"
                          className="form-control"
                          required
                          onChange={handleChange}
                        />

                        {rows.length > 0 && (
                          <>
                            <h5 className="mt-4">Please select the dates</h5>
                            {rows.map((_, index) => (
                              <div key={index} className="mt-3">
                                {index === 0 && (
                                  <label htmlFor={`date-${index}`}>
                                    Start Date
                                  </label>
                                )}
                                {index > 0 && index < rows.length - 1 && (
                                  <label htmlFor={`date-${index}`}>
                                    Other Dates
                                  </label>
                                )}
                                {index === rows.length - 1 &&
                                  rows.length > 1 && (
                                    <label htmlFor={`date-${index}`}>
                                      Last Date
                                    </label>
                                  )}
                                <input
                                  type="date"
                                  id={`date-${index}`}
                                  name={`date-${index}`}
                                  className="form-control"
                                  required
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                        <label htmlFor="time">Start Time</label>
                        <input
                          type="time"
                          id="time"
                          name="time"
                          className="form-control"
                          placeholder="hh:mm"
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                        <input
                          type="number"
                          id="pigeons"
                          name="pigeons"
                          placeholder="Number of Pigeons"
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                        <input
                          type="text"
                          id="helper"
                          name="helper"
                          placeholder="Helper Pigeon"
                          className="form-control"
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                        <input
                          type="number"
                          id="lofts"
                          name="lofts"
                          placeholder="Number of Lofts"
                          className="form-control"
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                        <input
                          type="number"
                          id="prizes"
                          name="prizes"
                          placeholder="Number of prizes"
                          className="form-control"
                          required
                          onChange={(e) => {
                            const prizeCount =
                              parseInt(e.target.value, 10) || 0;
                            setRows(Array.from({ length: prizeCount }));
                          }}
                        />

                        {rows.length > 0 && (
                          <>
                            <h5 className="mt-4">Please Add Prizes</h5>
                            {rows.map((_, index) => (
                              <div key={index} className="mt-3">
                                <label htmlFor={`amount-${index}`}>
                                  Prize {index + 1}
                                </label>
                                <input
                                  type="text"
                                  id={`amount-${index}`}
                                  name={`amount-${index}`}
                                  className="form-control"
                                  required
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </div>

                      <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                        <input
                          type="text"
                          id="owner"
                          name="owner"
                          placeholder="Add Participants"
                          className="form-control"
                          readOnly
                          value={selectedOwners
                            .map((owner) => owner.name)
                            .join(", ")}
                        />
                        <div className="dropdown">
                          <button
                            className="btn btn-secondary dropdown-toggle bbg"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Select Participating
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {owners.map((owner, index) => (
                              <li key={owner._id}>
                                <Link
                                  className="dropdown-item"
                                  href="#a"
                                  onClick={() => handleSelectOwner(owner)}
                                >
                                  <span>{index + 1}. </span>
                                  <img
                                    className="nng"
                                    src={`/uploads/${owner.image}`}
                                    alt=""
                                    width="50"
                                    height="50"
                                  />
                                  {owner.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="selected-owners mt-2">
                          {selectedOwners.map((owner, index) => (
                            <div
                              key={owner._id}
                              className="selected-owner d-flex align-items-center"
                            >
                              <button
                                type="button"
                                className="btn btn-danger btn-sm me-2 bbg"
                                onClick={() => handleRemoveOwner(owner)}
                              >
                                Remove
                              </button>
                              <span>
                                {index + 1}. {owner.name}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <button type="submit" id="dd" className="btn btn-primary ">
                      Save
                    </button>
                    <Toaster />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Tournament;
