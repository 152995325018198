import axios from "axios";
import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { IoMdEye } from "react-icons/io";
import { Link, useLocation, useParams } from "react-router-dom";

const Edittournament = () => {
  const { clubSlug, tournamentSlug } = useParams();
  const { slug } = useParams();

  const location = useLocation();

  const handleSidebarToggle = () => {
    const sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle("active");
  };

  // =========================================================================

  // Define the nav items with multiple links and nested collapsible links
  const navItems = [
    {
      id: 1,
      title: "Pigeon Owners",
      icon: "bi bi-person",
      links: [], // No sub-links, so this will render as a single link
      to: `/pigeonowner/${slug}`,
    },

    {
      id: 2,
      title: "Tournament",
      icon: "bi bi-trophy",
      links: [
        { to: `/Tournament/${slug}`, text: "Create Tournament" },
        { to: `/edit/${slug}`, text: "All Tournament" },
      ],
    },
    {
      id: 3,
      title: "Banner",
      icon: "bi bi-trophy",
      links: [], // No sub-links, so this will render as a single link
      to: `/banner/${slug}`,
    },
    // {
    //   id: 4,
    //   title: "All Partcipent",
    //   icon: "bi bi-trophy",
    //   links: [], // No sub-links, so this will render as a single link
    //   to: `/admin-user/${slug}`,
    // },
    {
      id: 4,
      title: "Logout",
      icon: "bi bi-box-arrow-right",
      links: [],
      to: "/",
    },
  ];
  // Add more nav items as needed

  // Initialize state to keep track of collapsed items
  const [collapsedItems, setCollapsedItems] = useState(
    navItems.map(() => false)
  );
  const [collapsedSubItems, setCollapsedSubItems] = useState(
    navItems.map((item) => item.links.map(() => false))
  );
  const isActiveNavItem = (item) => {
    return (
      location.pathname === item.to ||
      item.links.some((link) => location.pathname.startsWith(link.to))
    );
  };

  // Toggle function for collapse state of main items
  const handleCollapse = (index) => {
    setCollapsedItems(
      collapsedItems.map((item, i) => (i === index ? !item : item))
    );
  };

  // Toggle function for collapse state of sub-items
  const handleSubCollapse = (itemIndex, subIndex) => {
    setCollapsedSubItems(
      collapsedSubItems.map((subItems, i) =>
        i === itemIndex
          ? subItems.map((subItem, j) => (j === subIndex ? !subItem : subItem))
          : subItems
      )
    );
  };

  //===========================================================================get owners=========
  const [club, setClub] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    const fetchClubDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/auth/clubs/${slug}`
        );
        console.log("API Resp (Club)", response.data.club);
        setClub(response.data.club);
      } catch (error) {
        setError("Error fetching details");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchClubDetails();
  }, [slug]);
  // ===================== Get tournament ======================
  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/tournaments/${slug}`
        );
        console.log("API Response: (Tournaments) ", response);
        setTournaments(response.data.tournaments);
      } catch (error) {
        setError("Error fetching tournaments");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournaments();
  }, [slug]);

  //=================================================================================start date format
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return date.toLocaleTimeString([], options);
  };
  //===================================================================================dates
  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    return date.toISOString().split("T")[0];
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  //==================================================dates

  return (
    <>
      <div>
        {/* ======= Header ======= */}
        <header
          id="header"
          className="header fixed-top d-flex align-items-center"
        >
          <div className="d-flex align-items-center justify-content-between">
            <a href="/create" className="logo d-flex align-items-center">
              <span className="zz d-none d-lg-block">Sona Punjab</span>
            </a>
            <button
              id="toggle-sidebar-btn"
              className="bi bi-list toggle-sidebar-btn"
              onClick={handleSidebarToggle}
            />
          </div>
          {/* End Logo */}
          <div className="search-bar">
            <form className="search-form d-flex align-items-center">
              <input
                type="text"
                placeholder="Search"
                title="Enter search keyword"
              />
              <button type="submit" title="Search">
                <i className="bi bi-search" />
              </button>
            </form>
          </div>
          {/* End Search Bar */}
          <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-center">
              <li className="nav-item dropdown pe-3">
                <Link className="nav-link nav-profile d-flex align-items-center pe-0">
                  <span className="  ps-2">Welcome {club.name} </span>
                </Link>
                {/* End Profile Iamge Icon */}
              </li>
              {/* End Profile Nav */}
            </ul>
          </nav>
          {/* End Icons Navigation */}
        </header>
        {/* End Header */}
        {/* ======= Sidebar ======= */}
        <aside id="sidebar" className="sidebar" data-background-color="dark">
          <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-item">
              <a className="nav-link " href={`clubdetail/${slug}`}>
                <i className="bi bi-grid" />
                <span>Dashboard</span>
              </a>
            </li>
            {/* End Dashboard Nav */}

            {navItems.map((item, index) => (
              <li className="nav-item" key={item.id}>
                {item.links.length > 0 ? (
                  <>
                    <Link
                      className={`nav-link collapsed ${
                        isActiveNavItem(item) ? "active bg-orange" : ""
                      }`}
                      href="#a"
                      onClick={() => handleCollapse(index)}
                    >
                      <i className={item.icon} />
                      <span>{item.title}</span>
                      <i
                        className={`bi ${
                          collapsedItems[index]
                            ? "bi-chevron-up"
                            : "bi-chevron-down"
                        } ms-auto`}
                      />
                    </Link>
                    <ul
                      id={`components-nav-${item.id}`}
                      className={`nav-content collapse ${
                        collapsedItems[index] ? "show" : ""
                      }`}
                      data-bs-parent="#sidebar-nav"
                    >
                      {item.links.map((link, linkIndex) => (
                        <React.Fragment key={linkIndex}>
                          {link.subLinks ? (
                            <li>
                              <a
                                href={link.to}
                                className={`nav-link collapsed ${
                                  location.pathname === link.to
                                    ? "active bg-orange"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleSubCollapse(index, linkIndex)
                                }
                              >
                                <i className="bi bi-circle" />
                                <span>{link.text}</span>
                                <i
                                  className={`bi ${
                                    collapsedSubItems[index][linkIndex]
                                      ? "bi-chevron-up"
                                      : "bi-chevron-down"
                                  } ms-auto`}
                                />
                              </a>
                              <ul
                                className={`nav-content collapse ${
                                  collapsedSubItems[index][linkIndex]
                                    ? "show"
                                    : ""
                                }`}
                              >
                                {link.subLinks.map((subLink, subLinkIndex) => (
                                  <li key={subLinkIndex}>
                                    <Link to={subLink.to}>
                                      <i className="bi bi-circle" />
                                      <span>{subLink.text}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ) : (
                            <li>
                              <Link
                                to={link.to}
                                className={`nav-link ${
                                  location.pathname === link.to
                                    ? "active bg-orange"
                                    : ""
                                }`}
                              >
                                <i className="bi bi-circle" />
                                <span>{link.text}</span>
                              </Link>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                    </ul>
                  </>
                ) : item.title === "Logout" ? (
                  <button className="aab nav-link">
                    <i className={item.icon} />
                    <span id="qa">{item.title}</span>
                  </button>
                ) : (
                  <Link
                    to={item.to}
                    className={`aab nav-link ${
                      location.pathname === item.to ? "active bg-orange" : ""
                    }`}
                  >
                    <i className={item.icon} />
                    <span id="qa">{item.title}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </aside>
      </div>
      {/* End Sidebar*/}
      {/* ============================================================================ */}
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{club.cname}</h1>
          <nav>
            <ol className="breadcrumb mt-3">
              <li className="breadcrumb-item">
                <a href="/">{club.cname}</a>
              </li>
              <li className="breadcrumb-item active">Create Tournament</li>
            </ol>
          </nav>
        </div>
        <div>
          <section className="section-dashboard">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h1>Tournaments</h1>
                    {tournaments.length === 0 ? (
                      <p>No tournaments found</p>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>#</th>

                              <th>Poster</th>
                              <th>Name</th>
                              <th>Start Date</th>
                              <th>Days</th>
                              <th>Pigeons</th>
                              <th>Start Time</th>
                              <th>Lofts</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tournaments.map((tournament, index) => (
                              <tr key={tournament?._id}>
                                <td>{index + 1}</td>
                                <td>
                                  {tournament?.image ? (
                                    <img
                                      src={`/uploads/${tournament.image}`}
                                      alt={
                                        tournament?.name || "Tournament Image"
                                      }
                                      width="50"
                                      height="50"
                                    />
                                  ) : (
                                    "No Image"
                                  )}
                                </td>
                                <td>{tournament?.name || "No Name"}</td>
                                <td>
                                  {tournament?.dates?.length
                                    ? formatDate(tournament.dates[0])
                                    : "No Date"}
                                </td>
                                <td>{tournament?.numberOfDays || "No Days"}</td>
                                <td>
                                  {tournament?.numberOfPigeons || "No Pigeons"}
                                </td>
                                <td>
                                  {formatTime(tournament?.startTime) ||
                                    "No Start Time"}
                                </td>

                                <td>
                                  <td style={{ border: "none" }}>
                                    {/* numberOfLofts */}
                                    {tournament?.numberOfLofts || "2"}
                                  </td>
                                </td>
                                <td>
                                  <button className="btn-edit">
                                    <CiEdit />
                                  </button>
                                  <br></br>

                                  {/* <button className="btn btn-danger me-2 mb-1 mt-1">
                                    <MdOutlineDelete />
                                  </button> */}
                                  {/* <Link to={`/results/${clubSlug}/${tournamentSlug}`}>View Results</Link> */}

                                  <Link
                                    to={`/results/${club.slug}/${tournament?.nameSlug}`}
                                  >
                                    <button className=" me-2 mb-1 mt-1 btn-view">
                                      Add Results
                                    </button>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Edittournament;
