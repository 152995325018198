import { Route, Routes } from "react-router-dom";
import AdminUser from "./pages/AdminUser";
import BannerPage from "./pages/BannerPage";
import Clubdetail from "./pages/Clubdetail";
import Createclub from "./pages/Createclub";
import Dashboard from "./pages/Dashboard";
import Edittournament from "./pages/Edittournament";
import Login from "./pages/Login";
import Pigeonowner from "./pages/Pigeonowner";
import Tournament from "./pages/Tournament";
import Tournamentdetail from "./pages/Tournamentdetail";
import TournamentResults from "./pages/TournamentResults";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/banner/:slug" element={<BannerPage />} />
        <Route path="/admin-user/:slug" element={<AdminUser />} />
        <Route path="/create" element={<Createclub />} />
        <Route path="/clubdetail/:slug" element={<Clubdetail />} />
        <Route path="/pigeonowner/:slug" element={<Pigeonowner />} />
        <Route path="/Tournament/:slug" element={<Tournament />} />
        <Route path="/edit/:slug" element={<Edittournament />} />
        <Route
          path="/results/:clubSlug/:tournamentSlug"
          element={<TournamentResults />}
        />
        <Route path="/detail/:slug" element={<Tournamentdetail />} />
        {/* <Route
          path="/results/:clubSlug/:tournamentSlug"
          element={<Result1 />}
        /> */}
      </Routes>
    </>
  );
}

export default App;
