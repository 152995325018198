import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  TimePicker,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

const { Option } = Select;

const TournamentResults = () => {
  const location = useLocation();
  const { clubSlug, tournamentSlug, slug } = useParams();
  const [tournamentResults, setTournamentResults] = useState(null);
  const [results, setResults] = useState(null);
  const [numOfPigeons, setNumOfPigeons] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [collapsedItems, setCollapsedItems] = useState([]);
  const [collapsedSubItems, setCollapsedSubItems] = useState([]);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const navItems = [
    {
      id: 1,
      title: "Pigeon Owners",
      icon: "bi bi-person",
      links: [],
      to: `/pigeonowner/${slug}`,
    },
    {
      id: 2,
      title: "Tournament",
      icon: "bi bi-trophy",
      links: [
        { to: `/Tournament/${slug}`, text: "Create Tournament" },
        { to: `/edit/${slug}`, text: "All Tournament" },
      ],
    },
    // {
    //   id: 3,
    //   title: "Banner",
    //   icon: "bi bi-trophy",
    //   links: [],
    //   to: `/banner/${slug}`,
    // },
    {
      id: 4,
      title: "Logout",
      icon: "bi bi-box-arrow-right",
      links: [],
      to: "/"
    },
  ];

  useEffect(() => {
    setCollapsedItems(navItems.map(() => false));
    setCollapsedSubItems(navItems.map((item) => item.links.map(() => false)));
  }, []);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/results/${clubSlug}/${tournamentSlug}`
        );
        console.log("API Resp tournament ", response);

        if (response.status !== 200) {
          throw new Error("Failed to fetch results");
        }

        const data = response.data.tournament;
        setResults(data);
        setNumOfPigeons(data.numberOfPigeons);

        form.setFieldsValue({
          startTime: data.startTime,
          numberOfPigeons: data.numberOfPigeons,
          name: data.name,
        });
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (clubSlug && tournamentSlug) fetchResults();
  }, [clubSlug, tournamentSlug, form]);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/tournamenrt/results/${tournamentSlug}`
        );
        console.log("API Resp (RESULTS) ", response);
        console.log("tournamentSlug ----> : ", tournamentSlug);
        if (response.status !== 200) {
          throw new Error("Failed to fetch results");
        }

        setTournamentResults(response.data.results);
        console.log("(RESULTS) ---> ", response.data.results);
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (tournamentSlug) fetchResults();
  }, [tournamentSlug]);

  // --------- Add Results Request ---------

  const onFinish = async (values) => {
    const {
      name,
      ownerName,
      date,
      startTime,
      numberOfPigeons,
      ...pigeonTimes
    } = values;

    const timeList = Object.keys(pigeonTimes)
      .filter((key) => key.startsWith("pigeon_"))
      .map((key) => pigeonTimes[key].format("HH:mm"));

    const payload = {
      name,
      startTime,
      ownerName,
      date,
      numberOfPigeons,
      timeList: JSON.stringify(timeList),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/add/results/${tournamentSlug}`,
        payload
      );
      if (response.status === 201) {
        console.log("Results added successfully:", response.data);
        // Reset form fields after successful submission
        form.resetFields();
      }
    } catch (error) {
      console.error(
        "Error adding results:",
        error.response?.data || error.message
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handlePigeonChange = (value) => {
    setNumOfPigeons(value);
  };

  const handleSidebarToggle = () => {
    const sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle("active");
  };

  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  const isActiveNavItem = (item) => {
    return (
      location.pathname === item.to ||
      item.links.some((link) => location.pathname.startsWith(link.to))
    );
  };

  const handleCollapse = (index) => {
    setCollapsedItems(
      collapsedItems.map((item, i) => (i === index ? !item : item))
    );
  };

  const handleSubCollapse = (itemIndex, subIndex) => {
    setCollapsedSubItems(
      collapsedSubItems.map((subItems, i) =>
        i === itemIndex
          ? subItems.map((subItem, j) => (j === subIndex ? !subItem : subItem))
          : subItems
      )
    );
  };

  if (loading) return <h1 className="text-center p-5">Loading...</h1>;
  if (error) return <p>Error: {error}</p>;

  const filteredResults = selectedDate
    ? tournamentResults.filter(
        (result) =>
          new Date(result.date).toLocaleDateString() ===
          new Date(selectedDate).toLocaleDateString()
      )
    : tournamentResults;

  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };

  // console.log("RESULTS ===== ", tournamentResults);

  return (
    <>
      <div>
        {/* ======= Header ======= */}
        <header
          id="header"
          className="header fixed-top d-flex align-items-center"
        >
          <div className="d-flex align-items-center justify-content-between">
            <a href="/create" className="logo d-flex align-items-center">
              <span className="zz d-none d-lg-block">Sona Punjab</span>
            </a>
            <button
              id="toggle-sidebar-btn"
              className="bi bi-list toggle-sidebar-btn"
              onClick={handleSidebarToggle}
            />
          </div>
          {/* End Logo */}
          <div className="search-bar">
            <form className="search-form d-flex align-items-center">
              <input
                type="text"
                placeholder="Search"
                title="Enter search keyword"
              />
              <button type="submit" title="Search">
                <i className="bi bi-search" />
              </button>
            </form>
          </div>
          {/* End Search Bar */}

          {/* End Icons Navigation */}
        </header>
        {/* End Header */}
        {/* ======= Sidebar ======= */}
        <aside id="sidebar" className="sidebar" data-background-color="dark">
          <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-item">
              <a className="nav-link " href={`clubdetail/${slug}`}>
                <i className="bi bi-grid" />
                <span>Dashboard</span>
              </a>
            </li>
            {/* End Dashboard Nav */}

            {navItems.map((item, index) => (
              <li className="nav-item" key={item.id}>
                {item.links.length > 0 ? (
                  <>
                    <Link
                      className={`nav-link collapsed ${
                        isActiveNavItem(item) ? "active bg-orange" : ""
                      }`}
                      href="#a"
                      onClick={() => handleCollapse(index)}
                    >
                      <i className={item.icon} />
                      <span>{item.title}</span>
                      <i
                        className={`bi ${
                          collapsedItems[index]
                            ? "bi-chevron-up"
                            : "bi-chevron-down"
                        } ms-auto`}
                      />
                    </Link>
                    <ul
                      id={`components-nav-${item.id}`}
                      className={`nav-content collapse ${
                        collapsedItems[index] ? "show" : ""
                      }`}
                      data-bs-parent="#sidebar-nav"
                    >
                      {item.links.map((link, linkIndex) => (
                        <React.Fragment key={linkIndex}>
                          {link.subLinks ? (
                            <li>
                              <a
                                href={link.to}
                                className={`nav-link collapsed ${
                                  location.pathname === link.to
                                    ? "active bg-orange"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleSubCollapse(index, linkIndex)
                                }
                              >
                                <i className="bi bi-circle" />
                                <span>{link.text}</span>
                                <i
                                  className={`bi ${
                                    collapsedSubItems[index][linkIndex]
                                      ? "bi-chevron-up"
                                      : "bi-chevron-down"
                                  } ms-auto`}
                                />
                              </a>
                              <ul
                                className={`nav-content collapse ${
                                  collapsedSubItems[index][linkIndex]
                                    ? "show"
                                    : ""
                                }`}
                              >
                                {link.subLinks.map((subLink, subLinkIndex) => (
                                  <li key={subLinkIndex}>
                                    <Link to={subLink.to}>
                                      <i className="bi bi-circle" />
                                      <span>{subLink.text}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ) : (
                            <li>
                              <Link
                                to={link.to}
                                className={`nav-link ${
                                  location.pathname === link.to
                                    ? "active bg-orange"
                                    : ""
                                }`}
                              >
                                <i className="bi bi-circle" />
                                <span>{link.text}</span>
                              </Link>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                    </ul>
                  </>
                ) : item.title === "LOGOUT" ? (
                  <button className="aab nav-link">
                    <i className={item.icon} />
                    <span id="qa">{item.title}</span>
                  </button>
                ) : (
                  <Link
                    to={item.to}
                    className={`aab nav-link ${
                      location.pathname === item.to ? "active bg-orange" : ""
                    }`}
                  >
                    <i className={item.icon} />
                    <span id="qa">{item.title}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </aside>
      </div>
      {/* End Sidebar*/}

      <main id="main" className="main">
        {results && (
          <div className="tournament">
            <div className="results-title">
              <h2>{results?.name}</h2>
              <h3>- Start Time: {results?.startTime}</h3>
            </div>

            <div className="date-filter">
              <ul className="all-results-dates">
                {results?.dates?.map((date, i) => (
                  <li
                    key={i}
                    onClick={() => handleSelectDate(date)}
                    style={{
                      cursor: "pointer",
                      fontWeight: selectedDate === date ? "bold" : "normal",
                    }}
                  >
                    {new Date(date).toLocaleDateString()}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {/* <div className="date-filter">
          <Select
            placeholder="Select Date"
            onChange={handleSelectDate}
            style={{ width: "100%" }}
            size="large"
          >
            {results?.dates?.map((date, index) => (
              <Option key={index} value={date}>
                {new Date(date).toLocaleDateString()}
              </Option>
            ))}
          </Select>
        </div> */}

        <div>
          <section className="section-dashboard">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="add-record-heading">
                      <h1>Results</h1>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Flying Time</th>
                            {filteredResults?.length > 0 &&
                              Array.from({
                                length: Math.max(
                                  ...filteredResults.map(
                                    (result) => result?.timeList?.length
                                  )
                                ),
                              }).map((_, i) => (
                                <th key={`time-header-${i}`}># {i + 1}</th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {filteredResults && filteredResults?.length > 0 ? (
                            filteredResults.map((result, index) => (
                              <tr key={result?._id}>
                                <td>{index + 1}</td>
                                <td>{result?.ownerName}</td>
                                <td>{result?.startTime}</td>
                                {result?.timeList.map((time, i) => (
                                  <td key={`time-${result?._id}-${i}`}>
                                    {time}
                                  </td>
                                ))}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="8" className="no-results-msg">
                                No results available for this date
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default TournamentResults;
