import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./club.css";
import { Carousel } from "antd";
// import carsouselimg from "./5.jpg";
import img1 from "./assets/img1.jpg";
import banner2 from "./assets/12.jpg";
import banner3 from "./assets/13.jpg";

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
const Dashboard = () => {
  const { slug } = useParams();
  const [formValues, setFormValues] = useState({
    name: "",
    time: "",
    pigeons: [],
  });
  const [numOfPigeons, setNumOfPigeons] = useState(0);
  const [error, setError] = useState(null);
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [tournaments, setTournaments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClubClick = (slug) => {
    // Navigate to the Tournamentdetail page with the club slug
    navigate(`/detail/${slug}`);
  };
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return date.toLocaleTimeString([], options);
  };
  // Handle number of pigeon input change
  const handlePigeonChange = (value) => {
    setNumOfPigeons(value);
    const pigeonArray = Array(value).fill({ name: "", details: "" });
    setFormValues((prevValues) => ({
      ...prevValues,
      pigeons: pigeonArray,
    }));
  };

  const fetchClubs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/auth/clubs`
      );
      console.log("API Resp: ", response);
      if (response.data.success) {
        // Filter clubs to include only those with role 0
        const filteredClubs = response.data.clubs.filter(
          (club) => club.role === 0
        );
        setClubs(filteredClubs);
      }
    } catch (error) {
      console.error("Error fetching clubs:", error);
    }
  };

  useEffect(() => {
    fetchClubs();
  }, []);

  // api of tournaments
  const fetchTournaments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/tournaments`
      );
      console.log("API Response: (Tournaments) ", response);
      setTournaments(response.data.tournaments);
    } catch (error) {
      setError("Error fetching tournaments");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTournaments();
  }, [slug]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Services", path: "/services" },
    { name: "Contact", path: "/contact" },
    { name: "Blog", path: "/blog" },
  ];
  // console.log("the tournaments are---->", tournaments);

  return (
    <>
      <div>
        <main className="mains">
          <Carousel afterChange={onChange}>
            <div>
              <img src={img1} className="carousel-img" alt="carousel" />
            </div>
            <div>
              <img src={banner2} className="carousel-img" alt="carousel" />
            </div>
            <div>
              <img src={banner3} className="carousel-img" alt="carousel" />
            </div>
          </Carousel>
          {/* <header id="headers" className="headers d-flex align-items-center">
            <div className="container-fluid position-relative d-flex align-items-center container-color">
              <nav id="navmenu" className="navmenu">
                <ul className={isMenuOpen ? "open" : ""}>
                  <li>
                    <a href="/" className="active" style={{ color: "#e04622" }}>
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      className={` ${isMenuOpen ? "open" : ""}`}
                      href="/login"
                      style={{ color: "#e04622" }}
                    >
                      Login
                    </a>
                  </li>
                </ul>
                <i
                  className="mobile-nav-toggle d-xl-none bi bi-list"
                  onClick={toggleMenu}
                />
              </nav>
            </div>
          </header> */}
          <div className="header-div">
            <nav className="navbar navbar-expand-lg navbar-dark bg-navbar">
              <ul className="navbar-nav mr-auto">
                <li style={{ textAlign: "left" }}>
                  <a
                    href="/"
                    className="active"
                    style={{
                      color: "white",
                      marginLeft: "20px",
                    }}
                  >
                    Home
                  </a>
                </li>

                {tournaments.map((tournament) => (
                  <li>
                    <a
                      href="/"
                      className="active"
                      style={{ color: "white", marginLeft: "20px" }}
                    >
                      {tournament.name}
                    </a>
                  </li>
                ))}

                {/* <li style={{ textAlign: "left" }}>
                  <a
                    className={` ${isMenuOpen ? "open" : ""}`}
                    href="/login"
                    style={{ color: "white" }}
                  >
                    Login
                  </a>
                </li> */}
              </ul>
              <a
                className={` ${isMenuOpen ? "open" : ""}`}
                href="/login"
                style={{ color: "white" }}
              >
                Login
              </a>
            </nav>
            {/* <span className="navbar-text text-center">
              <b>Online users: 14</b>
            </span> */}
            {/* <span className="navbar-text ml-5" id="localdate"></span> */}
          </div>
          {/* tournament timing div */}
          <div className="start-time">
            <div className="start-time-des">
              <div className="tournament-title">Tournament Title</div>
              <div className="tournament-description">
                Tournament Description
              </div>
            </div>
          </div>
          {/* tournament dates */}
          <div className="submenu">
            <div className="btn-group">
              <a className="btn btn-submenu active" href="/">
                30.09.2024
              </a>
              <a className="btn btn-submenu" href="/">
                02.10.2024
              </a>
              <a className="btn btn-submenu" href="/">
                04.10.2024
              </a>
              <a className="btn btn-submenu" href="/">
                06.10.2024
              </a>
              <a className="btn btn-submenu" href="/">
                08.10.2024
              </a>
              <a className="btn btn-submenu" href="/">
                Total
              </a>
            </div>
          </div>
          {/* winner detail */}
          <div className="bs-cal">
            <div className="bs-callout bs-callout-info">
              <p>
                Lofts: 49, Total pigeons: 343, Pigeons landed: 283, Pigeons
                remaining: 60
              </p>
              <p>
                Todays winner pigeon time:{" "}
                <strong
                  className="bg-info text-white"
                  style={{ padding: "4px" }}
                >
                  19:42
                </strong>
                , Ch Saleem
              </p>
            </div>
          </div>
          {/* winner alert */}
          <div className="alert-main">
            <div className="alert alert-pak" role="alert">
              <strong>
                Tournament Info: السادات &nbsp;پیجن &nbsp;کلب &nbsp;دُھنی
              </strong>
            </div>
          </div>

          <section className="section-dashboard">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive-sm">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>#1</th>
                            <th>#2</th>
                            <th>#3</th>
                            <th>#4</th>
                            <th>#5</th>
                            <th>#6</th>
                            <th>#7</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tournaments.map((tournament, index) => (
                            <tr key={tournament?._id}>
                              <td>{index + 1}</td>{" "}
                              <td>
                                {tournament?.owners?.length ? (
                                  <div>
                                    {tournament.owners.map((owner) => (
                                      <p key={owner?.ownerId?._id}>
                                        {owner?.ownerName || "No Name"}
                                      </p>
                                    ))}
                                  </div>
                                ) : (
                                  "No Owners"
                                )}
                              </td>
                              <td>
                                {formatTime(tournament?.startTime) ||
                                  "No Start Time"}
                              </td>
                              <td>03:00</td>
                              <td>08:00</td>
                              <td>08:00</td>
                              <td>09:20</td>
                              <td>01:50</td>
                              <td>07:00</td>
                              <td>02:05</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
