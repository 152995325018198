import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
const TournamentDetail = () => {
  const { slug } = useParams(); // This is the club slug
  const [club, setClub] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tournaments, setTournaments] = useState([]);
  useEffect(() => {
    const fetchClubDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/auth/clubs/${slug}`
        );
        setClub(response.data.club);
      } catch (error) {
        setError("Error fetching details");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchClubDetails();
  }, [slug]);
  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/tournaments/${slug}`
        );
        if (response.data && response.data.tournaments.length > 0) {
          setTournaments(response.data.tournaments);
        }
      } catch (error) {
        console.error("Failed to fetch tournament details:", error);
      }
    };

    fetchTournaments();
  }, [slug]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (tournaments.length === 0) return <p>No tournament details available.</p>;

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <Link className="logo d-flex align-items-center" to="/">
            <span className="zz d-lg-block">Sona Punjab</span>
          </Link>
        </div>
        <div className="search-bar">
          <form className="search-form d-flex align-items-center">
            <input
              type="text"
              placeholder="Search"
              title="Enter search keyword"
            />
            <button type="submit" title="Search">
              <i className="bi bi-search" />
            </button>
          </form>
        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">
              <Link className="nav-link nav-profile d-flex align-items-center pe-0">
                <span className="ps-2">Welcome {club?.name} </span>
              </Link>
            </li>
          </ul>
        </nav>
      </header>

      <section className="section-dashboard">
        <div className="row">
          <h3 className="zzqq d-flex align-items-center mt-3 mb-3">
            Tournament Details
          </h3>
          {tournaments.map((tournament) => {
            const { _id, name, nameSlug, dates = [], image } = tournament;
            const dateOptions = {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            };

            const startDate =
              dates.length > 0
                ? new Date(dates[0]).toLocaleDateString("en-GB", dateOptions)
                : "N/A";
            const endDate =
              dates.length > 0
                ? new Date(dates[dates.length - 1]).toLocaleDateString(
                    "en-GB",
                    dateOptions
                  )
                : "N/A";

            return (
              <div key={_id} className="col-lg-12 mb-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h1 className="card-title">
                        <Link
                          to={`/results/${slug}/${nameSlug}`}
                          className="ffv"
                        >
                          {name}
                        </Link>
                      </h1>
                    </div>
                    {image && (
                      <img
                        src={`/uploads/${image}`}
                        alt={name}
                        width="280"
                        height="300"
                      />
                    )}
                    <p className="mt-3">
                      <strong>Start Date:</strong> {startDate}
                    </p>
                    <p>
                      <strong>End Date:</strong> {endDate}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default TournamentDetail;
