import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/login`, {
        name,
        password,
      });
      const { success, message, user, token } = response.data;

      if (success) {
        localStorage.setItem("token", token);

        // Navigate based on user role and id
        if (user.role === 1) {
          navigate("/create"); // Navigate to /create page if role is 1
        } else if (user.role === 0 && user.slug) {
          navigate(`/clubdetail/${user.slug}`);

          // else if (user.role === 0 && user.id) {
          //     navigate(`/clubdetail/${user.id}`); // Use id for navigation
        } else {
          alert("User slug is missing.");
        }
      } else {
        alert(message);
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Error logging in. Please try again.");
    }
  };

  return (
    <>
      <div id="www">
        <div className="form-containers">
          <form onSubmit={handleSubmit}>
            <h4 className="title">LOGIN</h4>
            <div className="mb-3">
              <input
                type="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                placeholder="ID*"
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                placeholder="Password*"
                required
              />
            </div>
            <button type="submit" id="l" className="btn btn-primary" style={{backgroundColor:'#0D6EFD'}}>
              LOGIN
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
